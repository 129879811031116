/**
 * @file error.interceptor.ts
 * @description this file implements interceptor to handler http errors
 */
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../core/services/auth/auth.service';
import { MultilingualService } from '../../core/services/multilingual/multilingual.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly multilingualService: MultilingualService
  ) {}

  /**
   * @description Interceptor to handle http errors
   * @param  HttpRequest request
   * @param  HttpHandler next
   * @returns Observable
   */
  public intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    let errMsg: any;
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.logout();
        } else if (
          error.status >= 500 ||
          error.status >= 503 ||
          error.status >= 502 ||
          error.error instanceof Blob
        ) {
          errMsg =
            this.multilingualService.langMap.ERROR_COMMON_SOMETHING_WENT_WRONG;
        } else if (
          typeof error.error === 'string' &&
          error.error.includes('{')
        ) {
          const errorDetails = JSON.parse(error.error);
          if (errorDetails.message) {
            errMsg = errorDetails.message;
          } else if (errorDetails.errorMessage) {
            errMsg = errorDetails.errorMessage;
          } else {
            errMsg =
              this.multilingualService.langMap
                .ERROR_COMMON_SOMETHING_WENT_WRONG;
          }
        } else if (typeof error.error === 'string') {
          errMsg = error.error;
        } else if (typeof error.error === 'object') {
          if (error.error.message) {
            errMsg = error.error.message;
          } else if (error.error.errorMessage) {
            errMsg = error.error.errorMessage;
          } else {
            errMsg =
              this.multilingualService.langMap
                .ERROR_COMMON_SOMETHING_WENT_WRONG;
          }
        } else {
          errMsg =
            this.multilingualService.langMap.ERROR_COMMON_SOMETHING_WENT_WRONG;
        }
        return throwError(() => errMsg);
      })
    );
  }
}
