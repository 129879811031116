/**
 * @file user.service.ts
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../core/services/http/http.service';
import { EligibilityVerificationResponse } from '../../interfaces/eligibility-verification-response.interface';
import { TokenService } from '../../core/services/token/token.service';
import jwtDecode from 'jwt-decode';
import { FamilyCoverageResponse } from '../../interfaces/family-coverage-response.interface';
import { Member } from '../../interfaces/member.interface';
import { AccountBalanceResponse } from '../../interfaces/account-balance-response.interface';
import { APIConstants } from '../services.constants';
import { AuthService } from '../../core/services/auth/auth.service';
import { EligibilityVerificationResponseSSO } from '../../interfaces/eligibility-verification-response-sso.interface';
import { AppConstants, RELATIONSHIP_CODE, USER_ELIGIBILITY_STATUS } from 'src/app/app.constants';
import { SSOJwtToken } from 'src/app/interfaces/auth-token.interface';
import { ClientEffDateResponse } from 'src/app/interfaces/historic-data.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userName: string | null = null;
  eligibilityVerificationResponse: EligibilityVerificationResponse | null = null;
  familyCoverageResponse: FamilyCoverageResponse | null = null;
  allFamilyCoverageResponse: FamilyCoverageResponse | null = null;
  member: Member | null = null;
  accountBalanceResponse: AccountBalanceResponse | null = null;
  // isPHISettingVisible!: boolean;
  isMemberTermed = false;
  isMemberFutureEffective = false;

  constructor(
    private readonly httpService: HttpService,
    private readonly tokenService: TokenService,
    private readonly authService: AuthService,
  ) { }

  getFirstName(): string {
    try {
      return jwtDecode<SSOJwtToken>(this.tokenService.ssoToken || '').extension_FirstName;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getClientId(): string {
    try {
      return jwtDecode<SSOJwtToken>(this.tokenService.ssoToken || '').extension_ClientId;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getCardId(): string {
    try {
      return jwtDecode<SSOJwtToken>(this.tokenService.ssoToken || '').extension_CardID;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getPersonCode(): string {
    try {
      return jwtDecode<SSOJwtToken>(this.tokenService.ssoToken || '').extension_PersonCode;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getSequenceCode(): string {
    try {
      return jwtDecode<SSOJwtToken>(this.tokenService.ssoToken || '').SequenceCode;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getLastName(): string {
    try {
      return jwtDecode<SSOJwtToken>(this.tokenService.ssoToken || '').extension_LastName;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getdob(): string {
    try {
      return jwtDecode<SSOJwtToken>(this.tokenService.ssoToken || '').extension_DateOfBirth;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getObjectId(): string {
    try {
      return jwtDecode<{ oid: string; }>(this.tokenService.azureIdToken || '').oid;
    } catch (error) {
      this.authService.logout();
      return '';
    }
  }

  getUserEligbility(): Observable<EligibilityVerificationResponse> {
    return this.httpService.get(`${APIConstants.USER_MGMT_SERVICES.GET_ELIGIBILITY_VERIFICATION}?username=${this.userName}`);
  }

  getUserEligbilitySSO(clientId: string, cardId: string, personCode: string, sequenceCode: string): Observable<EligibilityVerificationResponseSSO> {
    let strURL;
    if (personCode != "") {
      strURL = "clientId=" + clientId + "&cardId=" + cardId + "&personCode=" + personCode; //+ "&sequenceCode=" + SequenceCode
    }
    else if (sequenceCode != "") {
      strURL = "clientId=" + clientId + "&cardId=" + cardId + "&sequenceCode=" + sequenceCode;
    }
    return this.httpService.get(`${APIConstants.USER_MGMT_SERVICES.GET_ELIGIBILITY_VERIFICATION_SSO}?${strURL}`);
  }

  public isFutureEffectiveUser(): boolean {
    const userEligibilityStatus = this.eligibilityVerificationResponse?.eligibilityStatus;
    if (userEligibilityStatus === USER_ELIGIBILITY_STATUS.FUTURE_EFFECTIVE && this.isOrionUser()) {
      return true;
    }
    return false;
  }

  public isOrionUser(): boolean {
    const providerType = this.tokenService.getProviderType();
    return providerType.toLocaleLowerCase() === AppConstants.PROVIDER_TYPE_ORION.toLocaleLowerCase() ? true : false;
  }

  public isMigratedMember(): boolean {
    return this.tokenService.getIsMigratedMember(); 
  }

  public getSpousePartner(): Member | undefined {
    let spouse;
    if (this.isOrionUser()) {
      // For Orion users, use relationship code to determine the spouse partner
      spouse = this.allFamilyCoverageResponse?.members.find(member => (member.relationshipCode === RELATIONSHIP_CODE.SPOUSE || member.relationshipCode === RELATIONSHIP_CODE.CARDHOLDER) && member.relationshipCode !== this.eligibilityVerificationResponse?.relationshipCode);
    } else {
      spouse = this.allFamilyCoverageResponse?.members.find(member => (member.personCode === '02' || member.personCode === '01') && member.personCode !== this.eligibilityVerificationResponse?.personCode);
    }
    return spouse;
  }

  public fetchClientName(clientId: any): Observable<ClientEffDateResponse> {
    return this.httpService.get<ClientEffDateResponse>(`${APIConstants.HISTORICAl_DATA_FETCH.GET_CLIENT_EFFECTIVE_DATE}?clientId=${clientId}`);
  }

  public setClientName(clientName: string) {
    localStorage.setItem(AppConstants.CLIENT_NAME, clientName);
  }

  public getClientName(): string | null {
    const clientName = localStorage.getItem(AppConstants.CLIENT_NAME);
    return clientName ? clientName : '';
  }
}
