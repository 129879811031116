/**
 * @file token.interceptor.ts
 */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../../core/services/token/token.service';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/app.constants';
import { VersionOverrideType } from 'src/api-versions/api-version-config';
import { AppInsightsService } from 'src/app/services/app-insights/app-insights.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly tokenService: TokenService,
    private insightsService: AppInsightsService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const providerType = this.tokenService.getProviderType();

    // get API version to pass in header
    const version = this.getAPIVersion(request.url, request.method);

    // TODO: Remove after testing
    console.log('calculated version', version);
    console.log('-------------------------------')

    request = request.clone({
      setHeaders: {
        Authorization: this.tokenService.azureIdToken || '',
        'Authorization-SSO': this.tokenService.ssoToken || '',
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'ProviderType': providerType ? providerType : AppConstants.GUEST_PROVIDER_TYPE,
        'x-api-version': version
      }
    });
    return next.handle(request);
  }

  private getAPIVersion(url: string, method: string): string {
    // set API version to default version
    let version = environment.apiVersionConfig.defaultVersion;

    try {
      let baseUrl = url.split('?')[0];
      baseUrl = baseUrl.replace(environment.hostURL, "");
      console.log('Base URL', baseUrl);

      const apiOverrides: VersionOverrideType = environment.apiVersionConfig.versionOverrides;

      if (apiOverrides.hasOwnProperty(baseUrl)) {
        const apiDetails = apiOverrides[baseUrl];
        if (apiDetails.methods && apiDetails.methods.length > 0) {
          if (apiDetails.methods.includes(method)) {
            version = apiDetails.apiVersion;
          }
        } else {
          // if no method is specified, then set the overridden API version for all the APIs with baseURL
          version = apiDetails.apiVersion;
        }
      }
      return version;
    } catch (error) {
      const errorObj: Error = {
        name: "ADD API VERSION",
        message: `Exception while calculation API version`,
        stack: JSON.stringify(error)
      }
      this.insightsService.trackException(errorObj, 1);
      return version; // return default version
    }
  }
}
