/**
 * @file token.service.ts
 */
import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { AppConstants } from 'src/app/app.constants';
import { JwtAuthToken, SSOJwtToken } from 'src/app/interfaces/auth-token.interface';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private SSO_TOKEN = 'sso_token';
  private ID_TOKEN = 'id_token';
  private BENECARD_ACCESS_TOKEN = 'access_token';
  private EMAIL = 'email';
  private PROVIDER_TYPE = 'provider_type';

  constructor() { }

  public setSSOToken(ssoToken: string): void {
    localStorage.setItem(this.SSO_TOKEN, ssoToken);
  }

  public get ssoToken(): string | null {
    return localStorage.getItem(this.SSO_TOKEN);
  }

  public setAzureIdToken(idToken: string): void {
    localStorage.setItem(this.ID_TOKEN, idToken);
  }

  public get azureIdToken(): string | null {
    return localStorage.getItem(this.ID_TOKEN);
  }

  public setBenecardAccessToken(accessToken: string): void {
    localStorage.setItem(this.BENECARD_ACCESS_TOKEN, accessToken);
  }

  public get benecardAccessToken(): string | null {
    return localStorage.getItem(this.BENECARD_ACCESS_TOKEN);
  }

  public setSSOEmail(email: string): void {
    localStorage.setItem(this.EMAIL, email);
  }

  public setProviderType(providerType?: string) {
    if (providerType) {
      localStorage.setItem(this.PROVIDER_TYPE, providerType);
    } else {
      // if provider type is not provided, extract the type from token
      localStorage.setItem(this.PROVIDER_TYPE, this.getProviderTypeFromToken());
    }
  }

  public getProviderType(): string {
    const providerType = localStorage.getItem(this.PROVIDER_TYPE);
    return providerType ? providerType : '';
  }

  public getMigrationFlag(): boolean {
    return this.getIsMigratedMember();
  }

  public get ssoEmail(): string | null {
    return localStorage.getItem(this.EMAIL);
  }

  public get multilingualToken(): string {
    return '';
  }

  public getProviderTypeFromToken(): string {
    try {
      if (this.azureIdToken) {
        return jwtDecode<{ extension_ProviderType: string; }>(this.azureIdToken || '').extension_ProviderType;
      } else if (this.ssoToken) {
        return jwtDecode<SSOJwtToken>(this.ssoToken || '').extension_ProviderType;
      } else {
        return AppConstants.GUEST_PROVIDER_TYPE;
      }
    } catch (error) {
      return '';
    }
  }

  public getIsMigratedMember(): boolean {
    try {
      if (this.azureIdToken) {
        return jwtDecode<{ extension_IsMigrated: boolean; }>(this.azureIdToken || '').extension_IsMigrated;
      } else if (this.ssoToken) {
        return jwtDecode<SSOJwtToken>(this.ssoToken || '').extension_IsMigrated;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  public isForgotPasswordLogin(token: string): boolean {
    try {
      if (token) {
        return jwtDecode<JwtAuthToken>(token || '').tfp === AppConstants.PASSWORD_RESET_USER_FLOW_NAME;
      }
    } catch (error) {
      return false;
    }
    return false;
  }

  public getClientIdFromToken(): string {
    try {
      if (this.azureIdToken) {
        const clientID = jwtDecode<JwtAuthToken>(this.azureIdToken || '').extension_ClientId;
        const rxGrp = jwtDecode<JwtAuthToken>(this.azureIdToken || '').extension_RxGRP;
        return clientID || rxGrp || 'unknown';
      } else if (this.ssoToken) {
        const clientID = jwtDecode<SSOJwtToken>(this.ssoToken || '').extension_ClientId;
        const rxGrp = jwtDecode<SSOJwtToken>(this.ssoToken || '').extension_RxGRP;
        return clientID || rxGrp || 'unknown';
      } else {
        return 'unknown';
      }
    } catch (error) {
      return 'unknown';
    }
  }
}
