/**
 * @file member.service.ts
 */
import { Injectable } from '@angular/core';
import { HttpService } from '../../core/services/http/http.service';
import { catchError, defer, map, Observable, of } from 'rxjs';
import * as moment from 'moment-timezone';
import { PricingResponse } from '../../interfaces/pricing-response.interface';
import { FamilyCoverageResponse } from '../../interfaces/family-coverage-response.interface';
import { MedicationHistoryResponse } from '../../interfaces/medication-history-response.interface';
import { PharmacySearchResponse } from '../../interfaces/pharmacy-search-response.interface';
import { PharmacySearchRequest } from 'src/app/interfaces/request/pharmacy-search-request.interface';
import { UserService } from '../user/user.service';
import { AccountBalanceResponse } from 'src/app/interfaces/account-balance-response.interface';
import { APIConstants } from '../services.constants';
import { RequestOptions } from '../../interfaces/request-options.interface';
import { DrugPricingRequest } from '../../interfaces/request/drug-pricing-request.interface';
import { ClaimService } from '../claim/claim.service';
import { User } from '../../interfaces/user.interface';
import { GetPHIResponse } from '../../interfaces/phi-info.interface';
import { NotificationsBody } from '../../interfaces/notifications-body.interface';
import { ContactInfo } from 'src/app/interfaces/contact-info.interface';
import { CompleteProfileService } from 'src/app/pre-complete-profile/complete-profile.service';
import { Prescription } from 'src/app/interfaces/prescription.interface';
import { DrugDetailRequest } from 'src/app/interfaces/drug-detail-request.interface';
import { CmsService } from '../cms/cms.service';
import { AppConstants } from 'src/app/app.constants';
import { Member } from 'src/app/interfaces/member.interface';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  user!: User;

  constructor(
    private readonly httpService: HttpService,
    private readonly userService: UserService,
    private readonly claimService: ClaimService,
    private readonly completeProfileService: CompleteProfileService,
    private readonly cmsService: CmsService
  ) {}

  public drugPricing(req: DrugPricingRequest): Observable<PricingResponse> {
    return this.httpService.get<PricingResponse>(
      `${APIConstants.MEMBER_SERVICES.GET_DRUG_PRICING}?cardId=${
        this.userService.eligibilityVerificationResponse?.cardId
      }&clientId=${
        this.userService.eligibilityVerificationResponse?.clientId
      }&personCode=${
        this.userService.eligibilityVerificationResponse?.personCode
      }${this.getDrugPricingParams(req)}`
    );
  }

  public familyCoverage(
    cardId: string,
    clientId: string
  ): Observable<FamilyCoverageResponse> {
    return this.httpService.get<FamilyCoverageResponse>(
      `${APIConstants.MEMBER_SERVICES.GET_FAMILY_COVERAGE}?cardId=${cardId}&clientId=${clientId}`
    );
  }

  public medicationHistory(): Observable<MedicationHistoryResponse> {
    return this.httpService.get<MedicationHistoryResponse>(
      `${APIConstants.MEMBER_SERVICES.GET_MEDICATION_HISTORY}?cardId=${
        this.userService.eligibilityVerificationResponse?.cardId
      }&clientId=${
        this.userService.eligibilityVerificationResponse?.clientId
      }&personCode=${
        this.userService.eligibilityVerificationResponse?.personCode
      }&startDate=${this.claimService.formatDateRFC3339(
        this.claimService.getPreviousYear(new Date(), 1)
      )}&endDate=${this.claimService.formatDateRFC3339(new Date())}`
    );
  }

  public pharmacySearch(
    filter: PharmacySearchRequest
  ): Observable<PharmacySearchResponse> {
    if (!(filter.city.trim() && filter.state.trim())) {
      filter.city = '';
      filter.state = '';
    }
    if (!(filter.zipCode.trim() && filter.radius.trim())) {
      filter.zipCode = '';
      filter.radius = '';
    }
    return this.httpService.get<PharmacySearchResponse>(
      `${APIConstants.MEMBER_SERVICES.GET_PHARMACY_SEARCH}?cardId=${
        this.userService.eligibilityVerificationResponse?.cardId
      }&clientId=${
        this.userService.eligibilityVerificationResponse?.clientId
      }&personCode=${
        this.userService.eligibilityVerificationResponse?.personCode
      }${this.getSearchParams(filter)}`
    );
  }

  public getAccountBalance(): Observable<AccountBalanceResponse> {
    return this.httpService.get<AccountBalanceResponse>(
      `${APIConstants.MAIL_ORDER_ACCOUNT_SERVICE.GET_MAIL_ACCOUNT_BAL}?cardId=${this.userService.eligibilityVerificationResponse?.cardId}&clientId=${this.userService.eligibilityVerificationResponse?.clientId}&personCode=${this.userService.eligibilityVerificationResponse?.personCode}`
    );
  }

  public getMemberDetails(userName: string): Observable<User> {
    return this.httpService.get<User>(`${APIConstants.MEMBER_SERVICES.GET_MEMBER_DETAILS}?userName=${userName}`);
  }

  public getSsoMemberDetails(
    clientId?: string,
    cardId?: string,
    dateOfBirth?: string,
    firstName?: string,
    lastName?: string
  ): Observable<User> {
    return this.httpService.get<User>(
      `${APIConstants.MEMBER_SERVICES.GET_SSO_MEMBER_DETAILS}?clientId=${clientId}&cardId=${cardId}&dateOfBirth=${dateOfBirth}&firstName=${firstName}&lastName=${lastName}`
    );
  }

  public getUserName(oid: string): Observable<string> {
    return this.httpService.get<string>(
      `${APIConstants.MEMBER_SERVICES.GET_USER_NAME}?objectId=${oid}`
    );
  }

  public sendOTP(
    email: string,
    username: string | undefined
  ): Observable<string> {
    return this.httpService.get<string>(
      `${APIConstants.MEMBER_SERVICES.SEND_OTP}?email=${email}&username=${username}`
    );
  }

  public verifyOTP(
    otp: number,
    username: string | undefined
  ): Observable<string> {
    return this.httpService.get<string>(
      `${APIConstants.MEMBER_SERVICES.VERIFY_OTP}?username=${username}&otp=${otp}`
    );
  }

  public updateMemberDetails(user: User): Observable<string> {
    return this.httpService.post<string>(
      `${APIConstants.MEMBER_SERVICES.UPDATE_DETAILS}`,
      user
    );
  }

  public updateMemberCardId(username: string, cardId: string) {
    return this.httpService.post<string>(`${APIConstants.MEMBER_SERVICES.UPDATE_CARD_ID}?username=${username}&cardid=${cardId}`);
  }

  public getContactSSOInfo(): Observable<ContactInfo> {
    return this.httpService.get<ContactInfo>(
      `${APIConstants.USER_MGMT_SERVICES.GET_CONTACT_INFO}?clientId=${this.completeProfileService.user?.clientId}&cardId=${this.completeProfileService.user?.cardId}&personCode=${this.completeProfileService.user?.personCode}`
    );
  }
  public getContactInfo(): Observable<ContactInfo> {
    return this.httpService.get<ContactInfo>(
      `${APIConstants.USER_MGMT_SERVICES.GET_CONTACT_INFO}?clientId=${this.userService.eligibilityVerificationResponse?.clientId}&cardId=${this.userService.eligibilityVerificationResponse?.cardId}&personCode=${this.userService.eligibilityVerificationResponse?.personCode}`
    );
  }

  public UpdateSsoMemberDetail(user: User): Observable<string> {
    return this.httpService.post<string>(
      `${APIConstants.MEMBER_SERVICES.UPDATE_SSO_USER_DETAILS}`,
      user
    );
  }

  public completeProfile(user: User): Observable<string> {
    return this.httpService.post<string>(
      `${APIConstants.MEMBER_SERVICES.COMPLETE_PROFILE}`,
      user
    );
  }

  public getPHISettings(): Observable<GetPHIResponse> {
    return this.httpService.get<GetPHIResponse>(
      `${APIConstants.MEMBER_SERVICES.GET_PHI_SETTINGS}?cardId=${this.userService.eligibilityVerificationResponse?.cardId}&clientId=${this.userService.eligibilityVerificationResponse?.clientId}&personCode=${this.userService.eligibilityVerificationResponse?.personCode}`
    );
  }

  public updatePHISettings(
    phiInfo: GetPHIResponse
  ): Observable<{ statusCode: string; message: string }> {
    return this.httpService.put<{ statusCode: string; message: string }>(
      `${APIConstants.MEMBER_SERVICES.GET_PHI_SETTINGS}?cardId=${this.userService.eligibilityVerificationResponse?.cardId}&clientId=${this.userService.eligibilityVerificationResponse?.clientId}&personCode=${this.userService.eligibilityVerificationResponse?.personCode}`,
      phiInfo,
      { responseType: 'text' } as RequestOptions
    );
  }

  public getNotificationSettings(): Observable<NotificationsBody> {
    return this.httpService.get<NotificationsBody>(`${APIConstants.MEMBER_SERVICES.GET_NOTIFICATION_SETTINGS}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${this.userService.eligibilityVerificationResponse ?.personCode}`);
  }

  public updateNotificationSettings(notification: NotificationsBody): Observable<NotificationsBody> {
    return this.httpService.put<NotificationsBody>(`${APIConstants.MEMBER_SERVICES.GET_NOTIFICATION_SETTINGS}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${this.userService.eligibilityVerificationResponse ?.personCode}`, notification, { responseType: 'text' } as RequestOptions);
  }

  public sendMail(email: string): Observable<void> {
    return this.httpService.post<void>(
      `${APIConstants.MEMBER_SERVICES.SEND_EMAIL}`,
      { ToEmail: email }
    );
  }

  public getPrescriptionPdf(
    prescription: Prescription,
    isChargeShipEnabled: boolean
  ): Observable<Blob> {
    let formattedDate = moment().format('MM-DD-YYYY');
    let patientDateOfBirth = prescription.patientDateOfBirth
      ? moment(prescription.patientDateOfBirth).format('MM-DD-YYYY')
      : '';
    let dispensedDate = prescription.dispensedDate
      ? moment(prescription.dispensedDate).format('MM-DD-YYYY')
      : '';
    let rxDate = prescription.rxDate
      ? moment(prescription.rxDate).format('MM-DD-YYYY')
      : '';
    let expirationDate = prescription.expirationDate
      ? moment(prescription.expirationDate).format('MM-DD-YYYY')
      : '';
    let nextRefillDate = prescription.nextRefillDate
      ? moment(prescription.nextRefillDate).format('MM-DD-YYYY')
      : '';
    prescription.patientDateOfBirth = patientDateOfBirth;
    prescription.dispensedDate = dispensedDate;
    prescription.rxDate = rxDate;
    prescription.expirationDate = expirationDate;
    prescription.nextRefillDate = nextRefillDate;
    return this.httpService.post<Blob>(
      `${APIConstants.MEMBER_SERVICES.GET_PRESCRIPTION_PDF}`,
      { ...prescription, isChargeShipEnabled, timeStamp: formattedDate },
      { responseType: 'blob' } as RequestOptions
    );
  }

  public getDrugPdf(drugDetails: DrugDetailRequest | null): Observable<Blob> {
    let formattedDate = moment().format('MM-DD-YYYY');
    return this.httpService.post<Blob>(
      `${APIConstants.MEMBER_SERVICES.GET_DRUG_PDF}`,
      { ...drugDetails, timestamp: formattedDate },
      { responseType: 'blob' } as RequestOptions
    );
  }

  public showDigitalIdCard(memberClientId: string): Observable<boolean> {
    return this.cmsService.getConfigurationSettings().pipe(
      map((result) => {
        try {
          let clients: string =
            result[AppConstants.DOCKER_CONFIG_KEYS.DISABLE_DIGITAL_ID_CARDS];
          if (clients) {
            const clientsList = clients.split(',').map((x) => x.trim());
            if (memberClientId && clientsList && clientsList.length > 0) {
              return !clientsList.includes(memberClientId) ? true : false;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } catch (error) {
          return false;
        }
      })
    );
  }
  public temporaryIdCard(personCode: string): Observable<Blob> {
    return this.httpService.get<Blob>(
      `${APIConstants.MEMBER_SERVICES.GET_DIGITAL_ID_CARD}?cardId=${this.userService.eligibilityVerificationResponse ?.cardId}&clientId=${this.userService.eligibilityVerificationResponse ?.clientId}&personCode=${personCode}`,
      { responseType: 'blob' } as RequestOptions
    );
  }

  public explanationOfBenefits(selectedYear: number): Observable<Blob> {
    return this.httpService.get<Blob>(
      `${APIConstants.MEMBER_SERVICES.EOB}?cardId=${this.userService.eligibilityVerificationResponse?.cardId}&clientId=${this.userService.eligibilityVerificationResponse?.clientId}&personCode=${this.userService.eligibilityVerificationResponse?.personCode}&year=${selectedYear}`,
      { responseType: 'blob' } as RequestOptions
    );
  }

  private getDrugPricingParams(filter: DrugPricingRequest): string {
    let params = '';
    if (!filter.radius) {
      filter.zipCode = undefined;
    }
    if (!filter.zipCode || filter.zipCode.length !== 5) {
      filter.zipCode = undefined;
      filter.radius = undefined;
    }
    if (!filter.city) {
      filter.state = undefined;
    }
    if (!filter.state) {
      filter.city = undefined;
    }
    if (filter.radius) {
      params += `&radius=${filter.radius}`;
    }
    if (filter.zipCode) {
      params += `&zipCode=${filter.zipCode}`;
    }
    if (filter.pharmacyName) {
      params += `&pharmacyName=${filter.pharmacyName}`;
    }
    if (filter.city) {
      params += `&city=${filter.city}`;
    }
    if (filter.state) {
      params += `&state=${filter.state}`;
    }
    if (filter.ndcTuple) {
      params += `&ndcTuple=${filter.ndcTuple};${filter.quantity};${filter.daysSupply}`;
    }
    if (filter.perPage) {
      params += `&perPage=${filter.perPage}`;
    }
    if (filter.page) {
      params += `&page=${filter.page}`;
    }
    if (filter.inNetwork) {
      params += `&inNetwork=${filter.inNetwork}`;
    }
    if (filter.operating24Hours) {
      params += `&operating24Hours=${filter.operating24Hours}`;
    }
    if (filter.sort) {
      params += `&sort.sortByField=${filter.sort.sortByField}`;
      params += `&sort.sortType=${filter.sort.sortType.toLocaleUpperCase()}`;
    }
    return params;
  }

  private getSearchParams(filter: PharmacySearchRequest): string {
    let params = '';
    if (filter.radius) {
      params += `&radius=${filter.radius}`;
    }
    if (filter.zipCode) {
      params += `&zipCode=${filter.zipCode}`;
    }
    if (filter.pharmacyName) {
      params += `&pharmacyName=${filter.pharmacyName}`;
    }
    if (filter.city) {
      params += `&city=${filter.city}`;
    }
    if (filter.state) {
      params += `&state=${filter.state}`;
    }
    if (filter.perPage) {
      params += `&perPage=${filter.perPage}`;
    }
    if (filter.page) {
      params += `&page=${filter.page}`;
    }
    if (filter.inNetwork) {
      params += `&inNetwork=${filter.inNetwork}`;
    }
    if (filter.operating24Hours) {
      params += `&operating24Hours=${filter.operating24Hours}`;
    }
    if (filter.sort) {
      params += `&sort.sortByField=${filter.sort.sortByField}`;
      params += `&sort.sortType=${filter.sort.sortType.toLocaleUpperCase()}`;
    }
    return params;
  }


  /**
   * 
   * @returns returns the PHI settings and the flag to indicate if PHI setting section should be visible for the logged in member
   */
  public getPHIConfig(): Observable<{
    showPHISettings: boolean;
    phiConfig?: GetPHIResponse;
    error?: string;
  }> {
    const loggedInMember = this.userService.member;

    if (!loggedInMember) return of({ showPHISettings: false });

    return defer(() => {
      return this.getPHISettings().pipe(
        map((res) => {
          return (res.members || []).length
            ? { showPHISettings: true, phiConfig: res }
            : { showPHISettings: false };
        }),
        catchError((error) => {
          return of({ showPHISettings: true, error: error })
        })
      );
    });
  }

  public getCurrentMember(
    familyCoverageResponse: FamilyCoverageResponse,
    personCode: string
  ): Member {
    const emptyMemberObject = {
      allowedPhiPersonCodes: [],
      cardId: this.userService.eligibilityVerificationResponse
        ? this.userService.eligibilityVerificationResponse.cardId
        : '',
      dateOfBirth:
        this.user && this.user.dateOfBirth
          ? new Date(Date.parse(this.user.dateOfBirth))
          : new Date(),
      firstName: this.user && this.user.firstName ? this.user.firstName : '',
      gender: '',
      lastName: this.user && this.user.lastName ? this.user.lastName : '',
      personCode: this.userService.eligibilityVerificationResponse
        ? this.userService.eligibilityVerificationResponse.personCode
        : '',
      phiShared: false,
      relationshipCode: '',
      relationshipValue: '',
      cardImage: '',
      cardImageError: '',
    };

    if (!familyCoverageResponse || !familyCoverageResponse.members.length)
      return emptyMemberObject;

    const loggedInMember = familyCoverageResponse.members.find(
      (member) => member.personCode === personCode
    );

    if (loggedInMember) {
      return loggedInMember;
    } else {
      return emptyMemberObject;
    }
  }
}
